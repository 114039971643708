import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {

  constructor(public authSrvc: AuthService, private router: Router) { }

  ngOnInit() {
  }

  signOut() {
    this.authSrvc.clearLocalStorage();
    this.router.navigate(['login']);
  }
}
