import { Injectable } from '@angular/core';
import { Adapter } from './../../_interfaces/adapter';
import { StudentUltra } from './student.model';

@Injectable({providedIn: 'root'})

export class StudentAdapter implements Adapter<StudentUltra> {
    adapt(item: any): StudentUltra {
        return new StudentUltra(
            item.id,
            item.SchoolId,
            item.ssid,
            item.firstName,
            item.middleName,
            item.lastName,
            new Date(item.dob),
            item.GenderCode,
            item.RaceId,
            item.GradeId,
            item.FRPLStatus,
            item.IEPStatus,
            item.LEPStatus,
            item.GiftedTalented,
            item.DaysExcusedAbsences,
            item.DaysUnexcusedAbsences,
            item.CummulaiveGPA,
            item.Homeless,
            item.AddressId
        );
    }
}
