export class StudentUltra {
    constructor(
        public id: number,
        public SchoolId: number,
        public ssid: number,
        public firstName: string,
        public middleName: string,
        public lastName: string,
        public dob: Date,
        public GenderCode: string,
        public RaceId: number,
        public GradeId: number,
        public FRPLStatus: string,
        public IEPStatus: boolean,
        public LEPStatus: boolean,
        public GiftedTalented: string,
        public DaysExcusedAbsences: number,
        public DaysUnexcusedAbsences: number,
        public CummulaiveGPA: number,
        public Homeless: boolean,
        public AddressId: number
    ) {  }

    static adapt(item: any): StudentUltra {
        return new StudentUltra (
            item.id,
            item.SchoolId,
            item.ssid,
            item.firstName,
            item.middleName,
            item.lastName,
            new Date(item.dob),
            item.GenderCode,
            item.RaceId,
            item.GradeId,
            item.FRPLStatus,
            item.IEPStatus,
            item.LEPStatus,
            item.GiftedTalented,
            item.DaysExcusedAbsences,
            item.DaysUnexcusedAbsences,
            item.CummulaiveGPA,
            item.Homeless,
            item.AddressId
        );
    }
}

