import { Injectable } from '@angular/core';
import { RepositoryService } from 'src/app/_services/shared/repository.service';
import { LookupItem } from 'src/app/_interfaces/lookup/contract/Ilookup-item';
import { EnvironmentUrlService } from './environment-url.service';
import { ErrorHandlerService } from 'src/app/_services/shared/error-handler.service';


@Injectable()

export class LookupService {
public apiAddress: string;
public errorMessage: string;
public countyLkup: LookupItem[] = [];
public districtLkup: LookupItem[] = [];
public schoolLkup: LookupItem[] = [];
  constructor(private repository: RepositoryService, private envUrl: EnvironmentUrlService, private errorHandler: ErrorHandlerService) { }

 getCounty(): any {
    // this.apiAddress = 'lookup/counties/' + this.envUrl.defaultState as string;
    this.apiAddress = 'lookup/counties/KY'; // + this.envUrl.defaultState as string;
    // console.log(this.apiAddress);
    this.repository.getData(this.apiAddress)
    .subscribe (res => {
      console.log(res);
      this.countyLkup = res as LookupItem[];
      return res as LookupItem[];
    },
    (error) => {
      this.loadError(error);
    }
    );
  }

  getDistrictByCounty(countyId: number): any {
    this.apiAddress = 'lookup/district-by-county/' + countyId as string;
    this.repository.getData(this.apiAddress)
    .subscribe (res => {
      return res as LookupItem[];
    },
    (error) => {
      this.loadError(error);
    }
    );
  }
  getSchoolByDistrict(districtId: number): any {
    this.apiAddress = 'lookup/school-by-district/' + districtId as string;
    this.repository.getData(this.apiAddress)
    .subscribe (res => {
      return res as LookupItem[];
    },
    (error) => {
      this.loadError(error);
    }
    );
  }
  private loadError(error: any) {
    this.errorHandler.handleError(error);
    this.errorMessage = this.errorHandler.errorMessage;
  }
}
