import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SaveNote } from 'src/app/_model/note/studentsNote';
import { LookupItemModel } from 'src/app/_model/Lookup/lookup-item-model';


@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.css']
})
export class AddNoteComponent implements OnInit {

  constructor(public router: Router) { }

  public noteModel = new SaveNote();

  ngOnInit() {
  }

  onStudentSelected(eventArgs: LookupItemModel) {
  }
  onSubmit() {
    console.log('Note :' + this.noteModel.note);
  }
}
