import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
import { SampleDemoComponent } from './demo/view/sampledemo.component';
import { FormsDemoComponent } from './demo/view/formsdemo.component';
import { DataDemoComponent } from './demo/view/datademo.component';
import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
import { OverlaysDemoComponent } from './demo/view/overlaysdemo.component';
import { MenusDemoComponent } from './demo/view/menusdemo.component';
import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
import { FileDemoComponent } from './demo/view/filedemo.component';
import { MiscDemoComponent } from './demo/view/miscdemo.component';
import { EmptyDemoComponent } from './demo/view/emptydemo.component';
import { DocumentationComponent } from './demo/view/documentation.component';
import { HomeComponent } from './_components/shared/home/home.component';
import { LoginComponent } from './_components/login/login.component';
import { AddNoteComponent } from './_components/add-note/add-note/add-note.component';
import { NotFoundComponent } from './_components/shared/error-pages/not-found/not-found.component';
import { InternalServerComponent } from './_components/shared/error-pages/internal-server/internal-server.component';
import { AuthGuard } from './_guards/auth-guard-service';
import { SearchStudentComponent } from './_components/re-usable/search-student/search-student.component';

export const routes: Routes = [
    { path: '', component: LoginComponent},
    { path: 'home', component: HomeComponent},
    { path: 'my-dashboard', component: DashboardDemoComponent},
    { path: 'activity', loadChildren: () => import('./_components/activity/activity.module').then(m => m.ActivityModule) },
    { path: 'students', loadChildren: () => import('./_components/students/students.module').then(m => m.StudentsModule) },
    { path: 'add-note', component: AddNoteComponent },
    { path: '404', component: NotFoundComponent },
    { path: '500', component: InternalServerComponent },
    // { path: '', redirectTo: '/login', pathMatch: 'full' },
    // { path: '**', redirectTo: '/404', pathMatch: 'full'},
    { path: 'sample', component: SampleDemoComponent },
    { path: 'forms', component: FormsDemoComponent },
    { path: 'data', component: DataDemoComponent },
    { path: 'panels', component: PanelsDemoComponent },
    { path: 'overlays', component: OverlaysDemoComponent },
    { path: 'menus', component: MenusDemoComponent },
    { path: 'messages', component: MessagesDemoComponent },
    { path: 'charts', component: ChartsDemoComponent },
    { path: 'file', component: FileDemoComponent },
    { path: 'misc', component: MiscDemoComponent },
    { path: 'empty', component: EmptyDemoComponent },
    { path: 'documentation', component: DocumentationComponent },
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
