
export class StudentCourse {
    constructor(public id: number,
                public schoolId: number,
                public stateCourseName: string,
                public localCourseName: string,
                public termGrade: string,
                public termGpa: string,
                public finalGrade: string,
                public finalGpa: string
                ) {  }

                // get fullName(): string {
                //     return this.firstName + ' ' + this.lastName;
                // }
}

