import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Car} from '../domain/car';
import { IUserSchoolGradeXref } from 'src/app/_interfaces/lookup/contract/user-school-grade';

@Injectable()
export class CarService {

    constructor(private http: HttpClient) {}

    getCarsSmall() {
        return this.http.get<any>('assets/demo/data/cars-small.json')
                    .toPromise()
                    .then(res => res.data as Car[])
                    .then(data => data);
    }

    getCarsMedium() {
        return this.http.get<any>('assets/demo/data/cars-medium.json')
                    .toPromise()
                    .then(res => res.data as Car[])
                    .then(data => data);
    }

    getUserSchoolGrade() {
        return this.http.get<any>('assets/demo/data/user-school-grade-lookup.json');
        // return this.http.get<any>('assets/demo/data/user-school-grade-lookup.json')
        //             .toPromise()
        //             .then(res => res.data as IUserSchoolGradeXref[])
        //             .then(data => data);

    }

    getCarsLarge() {
        return this.http.get<any>('assets/demo/data/cars-large.json')
                    .toPromise()
                    .then(res => res.data as Car[])
                    .then(data => data);
    }
}
