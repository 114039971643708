import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EnvironmentUrlService } from 'src/app/_services/shared/environment-url.service';
import { User } from 'src/app/_model/user';
import { RepositoryService } from './shared/repository.service';


@Injectable({
  providedIn: 'root'
})

export class AuthService {
  public Url: string;
  public Http: HttpClient;
  public apiAddress: string;
  public userObj: User;

  constructor(public http: HttpClient,
              public repository: RepositoryService,
              public envUrl: EnvironmentUrlService) {
    this.Http = http;
    this.Url = envUrl.urlAddress;
  }

   loginbyUserId(user: User) {
    // console.log('server user val');
    // console.log(user);
    // return this.Http.post(this.Url + '/login', JSON.stringify(user))
    // .map (res => res);
    // return this.Http.get(this.Url + '/' + user.userid + '/' + user.password)
    // .map(response => response);
   }

   getGUSSUserBySchoolCode(schoolCode: number) {
    this.apiAddress = 'user/guss/' + schoolCode;
    return this.repository.getData(this.apiAddress);
  }

   login(uid: string, userPwd: string, schCode: number) {
     const userObj = {
      userid : uid,
      password : userPwd,
      schoolCode : schCode
     };

     return this.Http.post(this.Url + '/user/login', userObj);
   }

  addUserToLocalStorage(currentUser: User) {
    this.clearLocalStorage();
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  clearLocalStorage(): void {
    // console.log('cleared local storage');
    localStorage.removeItem('currentUser');
  }


get isAuthenticated(): boolean {
  if ( this.currerntUser && this.currerntUser.userRecId > 0)  {
    return true;
  }
  return false;
}
  // isAuthenticated(): boolean {
  //   // let jwtHelper= new JwtHelper();
  //   // let tokenValue = localStorage.getItem('tokenKey');
  //   // if (!tokenValue) return false;
  //   // let expDate= jwtHelper.getTokenExpirationDate(tokenValue);
  //   // let isExpired= jwtHelper.isTokenExpired(tokenValue);
  //   // return !isExpired;
  //   // OR return !tokenNotExpired(); // this predefined jwt function take care of token info

  //   // console.log('print userid from isLoggedIn method:' + userId);
  //   if ( this.currerntUser && this.currerntUser.userRecId > 0)  {
  //     // console.log('user logged in');
  //     return true;
  //   }
  //   // console.log('user not logged in');
  //   return false;
  // }
  get currerntUser(): User {
    // let token = localStorage.getItem('token');
    // if (!token) { return null; }
    // return new JwtHelper().decodeToken(token);
  //   this.ngRedux.subscribe( () => {
  //     const store = this.ngRedux.getState();
  //     if (store.currentUser) {
  //       this.userObj = store.currentUser;
  //     }
  // });

    this.userObj = JSON.parse(localStorage.getItem('currentUser')) as User;
    if (!this.userObj) { return null; }

    return this.userObj;
  }


  get UserId(): number {
    if (!this.currerntUser) { return -1; }
    return this.currerntUser.userRecId;
  }
  get userFullName(): string {
    // console.log('first name ' + this.currerntUser.firstName );
    return this.currerntUser.firstName + ' ' + this.currerntUser.lastName;

    // if ( !this.currerntUser) {
    //   return this.currerntUser.firstName + ' ' + this.currerntUser.lastName;
    // }
    // return 'No Name';
  }
  get isAdmin(): boolean {
    // if ( !this.currerntUser && this.currerntUser.userType === 'IT_ADMIN') {
    //   return true;
    // }
    return true;
    // return this.currerntUser.isAdmin;
  }
  // get isManager(): boolean {
  //   return this.currerntUser.isManager;
  //   // if ( !this.currerntUser && this.currerntUser.userType === 'IT_ADMIN') {
  //   //   return true;
  //   // }
  //   // return false;
  // }

  // get isRPC(): boolean {
  //   return this.currerntUser.isRPC;
  //   // if ( !this.currerntUser && this.currerntUser.userType === 'INST_ADMIN') {
  //   //   return true;
  //   // }
  //   // return false;
  // }

  // get isDPC(): boolean {
  //   return this.currerntUser.isDPC;
  //   // if ( !this.currerntUser && this.currerntUser.userType === 'INST_ADMIN') {
  //   //   return true;
  //   // }
  //   // return false;
  // }

}
