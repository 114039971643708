// --Extracting a resuable data service
import { Observable, throwError } from 'rxjs'; // observable object
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BadInput } from 'src/app/_common/bad-input';
import { NotFoundError } from 'src/app/_common/not-fount-error';
import { AppError } from 'src/app/_common/app-error';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private url: string, private http: HttpClient) { }

  getAll() {
    return this.http.get(this.url)
    .pipe(map(response => HttpResponse)),
    catchError(this.handleError);

  }
  getById(id: number) {
    return this.http.get(this.url + '/' + id)
        .pipe(map(response => HttpResponse)),
        catchError(this.handleError);
    // return this.http.get(this.url + '/' + id)
    //     .map(response => response)
    //     .catch(this.handleError);
  }
  getUserByIdAndPassword(userId: string, password: string) {
    return this.http.get(this.url + '/' + userId + '/' + password)
              .pipe(map(response => response)),
              catchError(this.handleError);
    // return this.http.get(this.url + '/' + userId + '/' + password)
    //     .map(response => response)
    //     .catch(this.handleError);
  }
  create(resource) {
    return this.http.post(this.url, JSON.stringify(resource))
            .pipe(map(response => response)),
            catchError(this.handleError);
    // return this.http.post(this.url, JSON.stringify(resource))
    //     .map(response => response)
    //     .catch(this.handleError);
  }
  // --Send only editable fields
  update(resource) {
    return this.http.patch(this.url + '/' + resource.id, JSON.stringify({gender: 'Male'}))
              .pipe(map(response => response)),
              catchError(this.handleError);
    // return this.http.patch(this.url + '/' + resource.id, JSON.stringify({gender: 'Male'}))
    //     .map(response => response)
    //     .catch(this.handleError);
  }
  // Send complete 'user' object to update
 /* updateUser(user) {
    return this.http.patch(this.url + '/' + user.id, JSON.stringify(user));
  }
  */
  // delete method
  delete(id) {
    return this.http.delete(this.url + '/' + id)
    .pipe(map(response => response)),
    catchError(this.handleError);
    // return this.http.delete(this.url + '/' + id)
    //     .map(response => response)
    //   .catch(this.handleError);
  }

  private handleError(error: Response) {
    if (error.status === 400) {
      return throwError(new BadInput(error.json()));
    } else if (error.status === 404) {
      return throwError(new NotFoundError());
    } else {
    return Observable.throw(new AppError(error));
    }
  }

}
