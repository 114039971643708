import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';


@Injectable()

export class EnvironmentUrlService {

  public urlAddress: string = environment.urlAddress;
  public defaultSchoolYear: number = environment.defaultSchoolYear;
  public defaultState: string = environment.defaultState;
  public defaultCounty: number = environment.defaultCounty;
  public defaultDistrictCode: number = environment.defaultDistrictCode;
  public defaultSchoolId: number = environment.defaultSchoolId;
  public defaultCreatedBy: number = environment.defaultCreatedBy;
  constructor() { }
}
