import { Component, OnInit } from '@angular/core';
import { AppComponent } from './app.component';
import {MessageService} from 'primeng/api';
import { AuthService } from './_services/auth.service';
import { User } from './_model/user';
import { select, NgRedux } from 'ng2-redux';
import { IAppState } from './_redux/store';
import { LookupItem } from './_interfaces/lookup/contract/Ilookup-item';
import { LookupItemModel } from './_model/Lookup/lookup-item-model';
import { StudentService } from './_services/student.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {
displaySchoolDialog = false;
schoolName = '';
userFullName = '';
    constructor(
        public app: AppComponent,
        private messageService: MessageService,
        private ngRedux: NgRedux<IAppState>) {
        ngRedux.subscribe( () => {
            const store = ngRedux.getState();
            // console.log('app_topbar.ngRedux state current user');
            // console.log(this.currUser);
            // console.log('current school', store.currentUser.currentSchool);
            if (store.currentUser) {
                this.userFullName = store.currentUser.firstName + ' ' + store.currentUser.lastName;
            }
            if (store.currentUser.currentSchool ) {
                this.schoolName = store.currentUser.currentSchool.name;
            }
        });
        // -- Ydhanapal : set default dark theme
        // --const defaultOrangeLightTheme = 'orange-light';
        // const defaultOrangeDarkTheme = 'orange-dark';
        // this.app.changeTheme(defaultOrangeDarkTheme);
    }
    ngOnInit() {
        // this.currUser = this.authSrc.currerntUser;
        // console.log('user obj from top menu',this.currUser);
    }

// YDhanapal - Test Message
showSchoolDialog() {
    this.displaySchoolDialog = true;
}
populateMessages() {
    this.messageService.addAll([
        {key: 'tc', severity: 'success', summary: 'FAFSA', detail: 'Completion Rate 90%'},
        {key: 'tc', severity: 'info', summary: 'Students Served', detail: '500 Students served!'},
        {key: 'tc', severity: 'info', summary: 'Educator Served', detail: '998 Educator served!'}
    ]);
}
    themeChange(e) {
        const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
        const href = themeLink.href;
        const themeFile = href.substring(href.lastIndexOf('/') + 1, href.lastIndexOf('.'));
        const themeTokens = themeFile.split('-');
        const themeName = themeTokens[1];
        const themeMode = themeTokens[2];
        const newThemeMode = (themeMode === 'dark') ? 'light' : 'dark';

        this.app.changeTheme(themeName + '-' + newThemeMode);

        // console.log('theme val:' + themeName + '-' + newThemeMode);
    }
}
