import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentUrlService } from './environment-url.service';

@Injectable()

export class RepositoryService {

  constructor(private http: HttpClient, private envUrl: EnvironmentUrlService) { }

  public getDataNew(route: string) {
    console.log(this.createCompleteRoute(route, this.envUrl.urlAddress));
    return this.http.get<any>(this.createCompleteRoute(route, this.envUrl.urlAddress));
  }

  public getData(route: string) {
    // console.log(this.createCompleteRoute(route, this.envUrl.urlAddress));
    return this.http.get(this.createCompleteRoute(route, this.envUrl.urlAddress));
  }

  public create(route: string, body) {
    // console.log('post url :', this.createCompleteRoute(route, this.envUrl.urlAddress));
    return this.http.post(this.createCompleteRoute(route, this.envUrl.urlAddress), body, {headers: this.generateHeaders()});
  }

  public update(route: string, body) {
    return this.http.put(this.createCompleteRoute(route, this.envUrl.urlAddress), body, {headers: this.generateHeaders()});
  }

  public delete(route: string) {
    return this.http.delete(this.createCompleteRoute(route, this.envUrl.urlAddress));
  }

  private createCompleteRoute(route: string, envAddress: string) {
    return `${envAddress}/${route}`;
  }

  private generateHeaders() {
    const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
    return headers;
  }

  // private generateHeaders() {
  //   return {
  //     headers: new HttpHeaders({'Content-Type': 'application/json'})
  //   }
  // }

}
