import { Adapter } from 'src/app/_interfaces/adapter';
import { FamilyMember } from './family-member';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class FamilyMemberAdapter implements Adapter<FamilyMember> {
    adapt(item: any): FamilyMember {
        return new FamilyMember (
            item.id,
            item.kdeid,
            item.firstName,
            item.middleName,
            item.lastName,
            item.memberTypeCode,
            item.primaryContact,
            item.addressId,
            item.homeless
        );
    }
}
