import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { User } from 'src/app/_model/user';
import { LookupService } from 'src/app/_services/shared/lookup.service';
import { LookupItem } from 'src/app/_interfaces/lookup/contract/Ilookup-item';

import { RepositoryService } from 'src/app/_services/shared/repository.service';
import { ErrorHandlerService } from 'src/app/_services/shared/error-handler.service';
import { EnvironmentUrlService } from 'src/app/_services/shared/environment-url.service';

import {Message} from 'primeng/components/common/api';
import {MessageService} from 'primeng/components/common/messageservice';
import { IUserSchoolGradeXref } from 'src/app/_interfaces/lookup/contract/user-school-grade';
import { CarService } from 'src/app/demo/service/carservice';
import { NgRedux } from 'ng2-redux';
import { select } from 'ng2-redux';

import { IAppState } from 'src/app/_redux/store';
import { INCREMENT, ADD_SCHOOL, ADD_USER } from 'src/app/_redux/action-variables';
import { Observable } from 'rxjs';
import { ISubscription } from 'rxjs/Subscription';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})


export class LoginComponent implements OnInit, OnDestroy {
  public invalidLogin: boolean;
  public countyLkup: LookupItem[] = [];
  public districtLkup: LookupItem[] = [];
  public schoolLkup: LookupItem[] = [];
  public apiAddress: string;
  public errorMessage: string;
  public pngMessage: Message[];
  public selectedCounty: LookupItem;
  public selectedDistrict: LookupItem;
  public selectedSchool: LookupItem;
  public userSchoolList: IUserSchoolGradeXref[];
  public userM: User;
public userId: string;
public userPwd: string;
public rememberMe: boolean;
public userName: string;
public initVal = 0;
counter = 0;
// @select(s => s.get('counter')) counter;
// -- many ways read REDUX store
// @select('counter') count;
// @select(['messaging','newMessages']) newMessages;
// @select((s: IAppState) => s.messaging.newMessages) newMessages;

//  @select((s: IAppState) => s.counter) counter: Observable<number>;
// private subscription: ISubscription;
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public authService: AuthService,
    public lookupService: LookupService,
    private repository: RepositoryService,
    private errorHandler: ErrorHandlerService,
    private envUrl: EnvironmentUrlService,
    private pngMessageSrc: MessageService,
    private userSchoolLkupSrvc: CarService,
    private ngRedux: NgRedux<IAppState>
    ) {
      // this.ngRedux.subscribe( () => {
      //   // console.log('ngRedux state');
      //   // console.log(ngRedux.getState());
      //   const store = this.ngRedux.getState();
      //   this.counter = store.counter;
      //   });
      // this.countyLkup = this.lookupService.getCounty();
      // console.log ('county lookup');
      // console.log(this.countyLkup);
      // console.log('constructor - current user');
      // console.log(JSON.parse(localStorage.getItem('currentUser')));
    }

  ngOnInit() {
    
    this.pngMessage = [];
   
    this.showWarnMessages();
  }

  increment() {
    this.ngRedux.dispatch({ type: INCREMENT, newValue: 100});
    // this.counter++;
    // console.log(this.counter);
  }

  showWarnMessages() {
    this.pngMessage = [];
    this.pngMessage.push ({ severity: 'warn', summary: 'Inavlid Username and / or password!', detail: ''});
  }

  getSchool() {
    // this.apiAddress = 'lookup/schools-by-district/' + this.selectedDistrict.code;
    // console.log('school-by-district ' + this.apiAddress);
    // this.apiAddress = 'lookup/schools';
    this.apiAddress = 'lookup/schools-by-user/' + this.userId;
    // console.log('school-by-user url : ' + this.apiAddress);
    // alert('userid blue event : ' + this.userId);
    this.repository.getData(this.apiAddress)
      .subscribe (res => {
        this.schoolLkup = res as LookupItem[];
        // console.log('school');
        // console.log(this.schoolLkup);

      },
      (error) => {
        this.parseError(error);
      }
      );
  }

  login(): void {
    // console.log( value);
    // const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');

    // console.log('user Id : ' + this.userId);
    // console.log('selected school : ', this.selectedSchool);
    this.authService.login(this.userId, this.userPwd,  Number(this.selectedSchool.code)).subscribe( res => {
      this.userM = res as User;
      // console.log(this.userM);
      if ( this.userM.userRecId <= 0) {
        this.invalidLogin = true;
        // this.showWarnMessages();
      } else {
        this.authService.clearLocalStorage();
        this.userM.userId = this.userId;
        this.userM.currentSchool = this.selectedSchool;
        this.userM.schoolYear = 20192020;
        this.userM.stateCode = 'KY';
        this.ngRedux.dispatch({ type: ADD_USER, curUser: this.userM});

        this.authService.addUserToLocalStorage(this.userM);
        // console.log('login- curr user ', this.authService.currerntUser);

        this.router.navigateByUrl('students/dashboard');
      }
    });
  }

  getUserGradesForSchool() {
    this.apiAddress = 'lookup/grade-by-user-and-school/' + this.userId + '/' + this.selectedSchool.code.toString();
    // console.log('school-by-user url : ' + this.apiAddress);
    // alert('userid blue event : ' + this.userId);
    this.repository.getData(this.apiAddress)
      .subscribe (res => {
        this.userM.grades = res as LookupItem[];
        // console.log('login-current user grades ', this.userM.grades);
        // console.log('school');
        // console.log(this.schoolLkup);
      },
      (error) => {
        this.parseError(error);
      }
      );
  }

  private parseError(error: any) {
    this.errorHandler.handleError(error);
    this.errorMessage = this.errorHandler.errorMessage;
  }
  ngOnDestroy() {

  }
}
