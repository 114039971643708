import { AuthService } from './../_services/auth.service';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

 canActivate() {
   if (this.authService.isAdmin) { return true; }
  // -- return to no-access screen if user not admin
   // this.router.navigate(['/no-access']);
   return false;
 }

}
