import { INCREMENT, GET_CUR_SCHOOL, ADD_SCHOOL, ADD_USER } from 'src/app/_redux/action-variables';
import { AppActionFunctions } from './app-action-functions';
import { LookupItem } from 'src/app/_interfaces/lookup/contract/Ilookup-item';
import { User } from 'src/app/_model/user';


export interface IAppState {
    // counter: number;
    // currentSchool?: LookupItem;
    currentUser?: User;
    // currentSchoolName?: string;
}

export const INITIAL_STATE: IAppState = {
    // counter : 0,
    currentUser: null
};

export function rootReducer(appState: IAppState, action): IAppState {
    const appAction = new AppActionFunctions(appState, action);
    switch (action.type) {
        // case INCREMENT: return { counter: appState.counter + 1 };
        // case INCREMENT:
        //     // appState.counter ++;
        //     // return tassign(appState, {counter: appState.counter + 1});
        //     return appAction.addCounter();
        // case ADD_SCHOOL:
        //     return appAction.addSchool();
        case ADD_USER:
            return appAction.addCurrentUser();

    }
    return appState;
}
