import { AuthService } from './../_services/auth.service';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) {
  }
  canActivate(route, state: RouterStateSnapshot) {
    // console.log('auth guard.isLoggedin :' + this.authService.isAuthenticated);
    if (this.authService.isAuthenticated) { return true; }

    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url}});
    // this.router.navigateByUrl('../assets/pages/login.html');

    // return false;
  }
}
