import { Adapter } from 'src/app/_interfaces/adapter';
import { Injectable } from '@angular/core';
import { StudentCourse } from './student-course';

@Injectable({
    providedIn: 'root'
})

export class StudentCourseAdapter implements Adapter<StudentCourse> {
    adapt(item: any): StudentCourse {
        return new StudentCourse (
            item.id,
            item.schoolId,
            item.stateCourseName,
            item.localCourseName,
            item.termGrade,
            item.termGpa,
            item.finalGrade,
            item.finalGpa
        );
    }
}
