export class FamilyMember {
    constructor(public id: number,
                public kdeid: number,
                public firstName: string,
                public middleName: string,
                public lastName: string,
                public memberTypeCode: string,
                public PrimaryContact: boolean,
                public AddressId: number,
                public Homeless: boolean
                ) {  }

                get fullName(): string {
                    return this.firstName + ' ' + this.lastName;
                }
                // static adapt(item: any): FamilyMember {
                //     return new FamilyMember (
                //         item.id,
                //         item.kdeid,
                //         item.firstName,
                //         item.middleName,
                //         item.lastName,
                //         item.memberTypeCode,
                //         item.PrimaryContact,
                //         item.AddressId,
                //         item.Homeless
                //         );
                // }
}

