import { tassign } from 'tassign';
import { IAppState } from './store';
import { AuthService } from '../_services/auth.service';
import { LookupItem } from 'src/app/_interfaces/lookup/contract/Ilookup-item';

export class AppActionFunctions {
    constructor(private appState: IAppState, private action, private authService?: AuthService) {}

    addCurrentUser(): IAppState {
        // console.log('school from redux action fn');
        // console.log(this.action.curSchool);
        this.appState.currentUser = this.action.curUser;
        const newSate: IAppState = {currentUser: this.appState.currentUser };
        return tassign(this.appState, newSate );
    }

    // addCounter(): IAppState {
    //     // const curSchool: LookupItem = this.authService.currerntUser.currentSchool;
    //     // console.log('redux action function');
    //     // console.log(curSchool);
    //     this.appState.counter = this.action.newValue;
    //     return tassign(this.appState, { counter: this.appState.counter + 1 });
    // }

    // addSchool(): IAppState {
    //     // console.log('school from redux action fn');
    //     // console.log(this.action.curSchool);
    //     this.appState.currentSchool = this.action.curSchool;
    //     const newSate: IAppState = {counter: this.appState.counter + 1, currentSchool: this.appState.currentSchool };
    //     return tassign(this.appState, newSate );
    // }
    // getCurrentSchool(): IAppState {
    //    const curSchool: LookupItem = this.authService.currerntUser.currentSchool;
    //    console.log('redux action function');
    //    console.log(curSchool);
    //    return tassign(this.appState, { counter: this.appState.counter + 1 });
    // //    return tassign(this.appState, { counter: this.appState.counter + 1, curSchool });
    // }

}

