// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // urlAddress: 'http://localhost:2403/guss/v1',
  urlAddress: 'https://test.api.cpe.ky.gov/guss/v1',
  defaultSchoolYear: 20182019,
  defaultState: 'KY',
  defaultDistrictCode : 41, // -- Bourbon County
  defaultCounty: 73, // -- Franklin
  defaultSchoolId: 41120, // -- Burbon county middle school
  defaultCreatedBy: 43 // -- emaher
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
