import { Adapter } from 'src/app/_interfaces/adapter';
import { Injectable } from '@angular/core';
import { LookupItemModel } from './lookup-item-model';

@Injectable({
    providedIn: 'root'
})

export class LookupItemAdapter implements Adapter<LookupItemModel> {
    adapt(item: any): LookupItemModel {
        return new LookupItemModel (
            item.id,
            item.code,
            item.name
        );
    }
}
