import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { RepositoryService } from './shared/repository.service';
import { EnvironmentUrlService } from './shared/environment-url.service';
import { ErrorHandlerService } from './shared/error-handler.service';
import { HttpClient } from '@angular/common/http';
import { FamilyMemberAdapter } from '../_model/students/family-member-adapter';
import { FamilyMember } from './../_model/students/family-member';
import { StudentAdapter } from './../_model/students/student-adapter';
import { DataService } from './shared/data.service';
import { StudentCourseAdapter } from './../_model/students/student-course-adapter';
import { StudentCourse } from '../_model/students/student-course';
import { LookupItemAdapter } from '../_model/Lookup/lookup-item-adapter';
import { LookupItemModel } from '../_model/Lookup/lookup-item-model';

@Injectable({
  providedIn: 'root'
})
export class StudentService extends DataService {

  public errorMessage: string;
  private apiAddress: string;
  private gradIds: string;
  constructor(public repository: RepositoryService,
              public enrUrl: EnvironmentUrlService,
              public adapter: FamilyMemberAdapter,
              public studAdapter: StudentAdapter,
              public studCourseAdapter: StudentCourseAdapter,
              public lkupAdapter: LookupItemAdapter,
              public errorHandler: ErrorHandlerService,
              public _http: HttpClient,
              http: HttpClient) {
                super(enrUrl.urlAddress, http);
              }

    getFamilyMemberlist(): Observable<FamilyMember []> {
      // this.apiAddress = 'https://test.api.cpe.ky.gov/guss/v1/family-members/10845';
      // console.log( 'api addres ' + this.apiAddress);
      return this.repository.getData(this.apiAddress)
        .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))),
        );
    }

    getStudentCourses(studentId: number): Observable<StudentCourse[]> {
      // this.apiAddress = 'student-courses/10845';
      this.apiAddress = 'student-courses/' + studentId as string ;
      return this.repository.getData(this.apiAddress)
        .pipe(map((data: any[]) => data.map(item => this.studCourseAdapter.adapt(item))));
      // return this.repository.getData(this.apiAddress)
      //   .pipe(map((data: any[]) => {(item => this.studCourseAdapter.adapt(item))} ));
    }

    list(): Observable<FamilyMember[]> {
      this.apiAddress = 'family-members/10845';
      return this.repository.getData(this.apiAddress)
        .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getStudentLookupForSchool(schoolCode: string): Observable<LookupItemModel[]> {
      this.apiAddress = 'students-lookup-by-school/' + schoolCode as string ;
      return this.repository.getData(this.apiAddress)
        .pipe(map((data: any[]) => data.map(item => this.lkupAdapter.adapt(item))));
    }

  getStudentLookupForSchoolAndGrades(schoolCode: number, gradeList: LookupItemModel[]): Observable<LookupItemModel[]> {
      this.gradIds = '';
      gradeList.forEach((item) =>  {
        if (item.id !== -1) {
          if ( this.gradIds === '') {
            this.gradIds =  item.id.toString();
          } else {
            this.gradIds = this.gradIds + ',' + item.id.toString();
          }
        }
      });

      this.apiAddress = 'students-lookup-by-school-and-gradeIds/' + schoolCode + '/' + this.gradIds ;
      return this.repository.getData(this.apiAddress)
        .pipe(map((data: any[]) => data.map(item => this.lkupAdapter.adapt(item))));
    }

  getStudentLookupForSchoolAndGrade(schoolCode: number, gradeId: number): Observable<LookupItemModel[]> {
      this.apiAddress = 'students-lookup-by-school-and-grade/' + schoolCode + '/' + gradeId as string ;
      return this.repository.getData(this.apiAddress)
        .pipe(map((data: any[]) => data.map(item => this.lkupAdapter.adapt(item))));
    }

    public getStudent(studentId: number): any {
      this.apiAddress = 'ultra-student/' + studentId as string ;
      // console.log(this.apiAddress);
      this.repository.getData(this.apiAddress)
      .subscribe (res => {
        // console.log ('ultra student info');
        // console.log (res);
        return res;
      },
      (error) => {
        this.parseError(error);
        return this.errorMessage;
      }
      );
    }

    getStudentFamilyNodeBySchoolAndGrade(KDESchoolCode: number, gradeId: number): any {

      this.apiAddress = 'students-with-family-ng-treenode/' + KDESchoolCode as string + '/' + gradeId as string;
      // this.apiAddress = 'students-with-family/' + this.envUrl.defaultSchoolId as string + '/' + gid as string;
      // this.nodeService.getFilesystem().then(files => this.files4 = files);
      return this.repository.getData(this.apiAddress);
    }

    getStudentFamilyNodeTogetherBySchoolAndGrade(KDESchoolCode: number, gradeId: number): any {

      this.apiAddress = 'students-with-family-ng-treenode-together/' + KDESchoolCode as string + '/' + gradeId as string;
      // this.apiAddress = 'students-with-family/' + this.envUrl.defaultSchoolId as string + '/' + gid as string;
      // this.nodeService.getFilesystem().then(files => this.files4 = files);
      return this.repository.getData(this.apiAddress);
    }

  private parseError(error: any) {
    this.errorHandler.handleError(error);
    this.errorMessage = this.errorHandler.errorMessage;
  }
}
