import { ErrorHandler } from '@angular/core';


// Global Error Handling
export class AppErrorHandler implements ErrorHandler {
    handleError(error) {
        // alert('An unexpted error occured from app-error-handler class');
        console.log(error);
    }
}
