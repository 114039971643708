export class SaveNote {
    id: number;
    schoolYear: number;
    stateCode: string;
    countyCode: number;
    schoolId: number;
    studentId: number;
    familyMemberId: number;
    note: string;
    active: boolean;
    createdBy: number;
 }

export class NoteListViewModel {
    id: number;
    studentName: string;
    familyMemberName: string;
    note: string;
    active: boolean;
 }



